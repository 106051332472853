export async function waitForTransitionsToComplete(elm) {
  try {
    return await Promise.all(
      elm.getAnimations().map((animation) => animation.finished)
    )
  } catch (e) {
    return true
  }
}

export async function runAfterTransitionsComplete(elm, cb) {
  await waitForTransitionsToComplete(elm)
  return cb()
}
